import React from 'react'

import MuiAccordion from '@material-ui/core/Accordion'
import MuiAccordionSummary from '@material-ui/core/AccordionSummary'
import MuiAccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'

import { grey } from '@material-ui/core/colors'

import { makeStyles, withStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  accordion: {},
}))

const WrappedAccordion = withStyles((theme) => ({
  root: {
    borderRadius: '2px',
    '&::before': {
      height: 0,
    },
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}))(MuiAccordion)

const Summary = withStyles((theme) => ({
  root: {
    minHeight: 'auto',
    '&$expanded': {
      minHeight: 'auto',
      margin: theme.spacing(0),
      borderBottom: '1px solid' + theme.palette.border.main,
    },
    backgroundColor: theme.palette.background.light,
  },
  content: {
    margin: theme.spacing(1, 0),
    '&$expanded': {
      margin: theme.spacing(1, 0),
    },
    fontSize: '1.0em',
  },
  expandIcon: {
    padding: theme.spacing(1),
  },
  expanded: {},
}))(MuiAccordionSummary)

const Details = withStyles((theme) => ({
  root: {
    display: 'block',
    padding: theme.spacing(0, 2),
  },
}))(MuiAccordionDetails)

const Accordion = ({ summary, children }) => {
  const styles = useStyles()

  return (
    <WrappedAccordion data-test-id="root">
      <Summary expandIcon={<ExpandMoreIcon />} data-test-id="summary">
        {summary}
      </Summary>
      <Details>{children}</Details>
    </WrappedAccordion>
  )
}

export default Accordion
