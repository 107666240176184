import React from 'react'
import { MDXProvider } from '@mdx-js/react'

import Typography from '@material-ui/core/Typography'
import Accordion from '@components/Accordion'

import { withStyles } from '@material-ui/core/styles'

/**
 * There is a HUGE gotcha with this component
 * The first line will NOT render as markdown. After years of using tools made
 * by the javascript community, you learn not to even try to understand it. Sorry.
 *
 * Bad (it will render as text with the *, not in bold):
 * <Solution>
 *   **This is bold**, right?
 * </Solution>
 *
 * Good (works as intended):
 * <Solution>
 *
 *   **This is bold**, right?
 * </Solution>
 */
const Solution = ({ children }) => {
  return (
    <Accordion summary="☑️ Solution">
      <MDXProvider>{children}</MDXProvider>
    </Accordion>
  )
}

export default Solution
