import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { deepOrange, lime, red, grey } from '@material-ui/core/colors'

let theme = createMuiTheme({
  palette: {
    primary: {
      main: '#bd252b',
    },
    secondary: {
      main: '#7f7f7f',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: '#fff',
      dark: grey[200],
      light: '#f5f5f5',
    },
    border: {
      main: '#A9A9A9',
    },
  },
  typography: {
    fontSize: 12,
    h1: {
      fontSize: '3rem',
      marginBottom: '1.75rem',
    },
    h2: {
      fontSize: '2.3rem',
      margin: '1.5rem 0',
      marginTop: '50px',
    },
    h3: {
      fontSize: '1.8rem',
      margin: '3rem 0 1rem 0',
      fontWeight: '300',
    },
    h4: {
      fontSize: '1.4 rem',
      margin: '3rem 0 1rem 0',
      fontWeight: '500',
    },
    h5: {
      fontSize: '1rem',
      margin: '1rem 0',
    },
    h6: {
      fontSize: '.9rem',
      margin: '.9rem 0',
    },
    body1: {
      fontSize: '1rem',
      margin: '1rem 0',
      marginTop: '0.8rem',
      lineHeight: '1.6',
    },
    body2: {
      fontSize: '1rem',
    },
  },
  drawerWidth: '240px',
})

theme = responsiveFontSizes(theme)

export default theme
