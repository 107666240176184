import React from 'react'

import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'

import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { yellow } from '@material-ui/core/colors'

const useStyles = makeStyles((theme) => ({
  announcement: {
    padding: theme.spacing(0.5, 1.5),
    margin: theme.spacing(2, 1),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  hidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    margin: theme.spacing(1, 0),
  },
  warn: {
    backgroundColor: '#FED8B1',
  },
}))

export const AnnouncementType = {
  MESSAGE: 'message',
  WARNING: 'warning',
}

const Announcement = ({ title, type, children }) => {
  const styles = useStyles()

  const hideHeader = !title && !type

  const warnType = type == AnnouncementType.WARNING

  const mapIcon = (type) => {
    const icons = {
      message: AnnouncementType.MESSAGE,
      warning: AnnouncementType.WARNING,
    }

    return icons[type]
  }

  return (
    <Card
      className={clsx(styles.announcement, { [styles.warn]: warnType })}
      elevation={2}
    >
      <div className={clsx(styles.header, { [styles.hidden]: hideHeader })}>
        <Typography variant="h3" className={styles.title}>
          {title}
        </Typography>
        <Icon>{mapIcon(type)}</Icon>
      </div>

      <div className={styles.body}>{children}</div>
    </Card>
  )
}

export default Announcement
